import styles from './bannerSection.module.scss';
import IconTextBlock from './IconTextBlock';
import Link from 'next/link';

const BannerSection = ({ children, prefix }) => {
  return (
    <section className={styles.banner}>
      {children}
      <div className={styles.banner__wrapper}>
        <h2 className={styles.banner__title}>
          {prefix} getting started on Cap!
        </h2>

        <div className={styles.banner__content}>
          <ul className={styles.banner__list}>
            <li>
              <IconTextBlock
                title={<Link href="/signup">Sign up</Link>}
                description="Create an account to enrol your business or investor account."
                icon="/marketing/signup-icon.svg"
              />
            </li>
            <li>
              <IconTextBlock
                title="Get verified"
                description="Cap! management will verify your company and ensure it meets certain qualifying criteria before being published."
                icon="/marketing/verified.svg"
              />
            </li>
            <li>
              {prefix === "Companies" ? (
                <IconTextBlock
                  title="US$99 per month"
                  description="Join Cap! and utilise daily tools for overseeing your company's investors and capital raises."
                  icon="/marketing/no-fees.svg"
                />
              ) : (
                <IconTextBlock
                  title="No fees attached"
                  description="Enrol and browse business profiles, access information on your investments and company data room facilities."
                  icon="/marketing/no-fees.svg"
                />
              )}
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default BannerSection;


