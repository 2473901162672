import styles from './iconTextBlock.module.scss';

const IconTextBlock = ({ title, description, icon }) => {
  return (
    <div className={styles.iconText}>
      <div className={styles.iconText__icon}>
        <img src={icon} alt={title}/>
      </div>
      <div>
        <p className={styles.iconText__title}>{title}</p>
        <p className={styles.iconText__desc}>{description}</p>
      </div>
    </div>
  );
};

export default IconTextBlock;

