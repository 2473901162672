import Slide from "./Slide";

export const slides = [
  <Slide
    key={0}
    image={<img src="/marketing/sxollie.png" alt="Sxollie Testimonial" />}
    text={
      <>
        <p>
          <strong>
            @Carta Advisory - led by @Sam Pearson - was instrumental in
            assisting SXOLLIE to grow the business. Sam was able to deliver on
            the financial mandate within the time frame specified. Moreover, Sam
            provided really insightful input into the financial and legal
            aspects throughout the project journey to ensure a successful
            outcome.
          </strong>
        </p>

        <p>
          Sam acts with integrity and his views are always balanced and fair.
          For that reason, all parties have commented on how easy it was to work
          with Sam. We would highly recommend the services to other high growth
          businesses.
        </p>
      </>
    }
    author="Laura Clacey"
    role="Founder & CEO, SXOLLIE"
    location="London, UK"
    locationFlag={<img src="/marketing/flag_uk.png" alt="London, UK" />}
  />,
  <Slide
    key={1}
    image={<img src="/marketing/duchess.png" alt="Duchess Testimonial" />}
    text={
      <>
        <p>
          <strong>
            Carta Advisory acted as financial adviser to The Duchess for our
            equity capital raise in 2021. Sam was able to advise us on how to
            position our business as well as connect us with relevant
            professional investors (both trade and financial).
          </strong>
        </p>

        <p>
          The capital raise was a great success with RMB – as sole investor –
          investing the required capital as a growth equity partner. Sam worked
          with us like a member of our own team providing high-quality work,
          insights, and experience which was incredibly valuable. I would
          recommend their service with full confidence.
        </p>
      </>
    }
    author="Johannes Le Roux"
    role="Founder & Sales Director at The Duchess Alcohol-Free Drinks"
    location="Cape Town, South Africa"
    locationFlag={
      <img src="/marketing/flag_za.png" alt="Cape Town, South Africa" />
    }
  />,
  <Slide
    key={2}
    image={<img src="/marketing/cocacola.png" alt="Coca-Cola Testimonial" />}
    text={
      <>
        <p>
          <strong>
            Ran a competitive refinancing process to secure the most suitable
            and best priced debt finance solution for Western Cape Fruit
            Processors.
          </strong>
        </p>

        <p>
          I have worked with Sam on a number on transactions, over many years.
          Consistently, Sam has added tremendous value and been of great help.
          His experience, diligence and tenacity has helped us get transactions
          closed out, always successfully.
        </p>

        <p>
          Mergers/acquisitions/sales processes never close out overnight. Sam’s
          persistent pushing forward has been invaluable, never dropping a catch
          ! Also: Sam’s ethics are beyond reproach.
        </p>
      </>
    }
    author="Walter Leonhardt"
    role="Financial Director at Coca-Cola Beverages South Africa`"
    location="Cape Town, South Africa"
    locationFlag={
      <img src="/marketing/flag_za.png" alt="Cape Town, South Africa" />
    }
  />,
];


