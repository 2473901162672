import styles from './hero.module.scss';
import Link from 'next/link';

const Hero = ({ children }) => {
  return (
    <section className={styles.hero}>
      {children}
      <div className={styles.hero__wrapper}>
        <h1 className={styles.hero__headline}>
          <img
            className={styles.hero__logo}
            src="/cap-logo-no-tagline.svg"
            alt="Cap! Logo"
          />
          Simplifying capital for companies and investors
        </h1>

        <p className={styles.hero__description}>
          An innovative portal for company and capital management.
        </p>

        <Link href="/signup">
          <button className={styles.hero__btn}>Sign up today</button>
        </Link>
      </div>
    </section>
  );
};

export default Hero;

