import styles from './forInvestors.module.scss';
import TitleSection from "./TitleSection";
import Link from 'next/link';

const ForInvestors = () => {
  return (
    <TitleSection
      containerClass={styles.forInvestors}
      heading="Who we are for"
      title="For Investors"
      description="Join cap! with no fees attached"
      image={
        <img
          src="/marketing/for-investors.png"
          alt="For investors"
          style={{ width: "560px" }}
        />
      }
      swapImage
    >
      <p>
        <strong>
          Cap! is an exclusive platform to provide angel, venture capital
          and larger institutional fund managers with access to great business
          opportunities.
        </strong>
      </p>
      <p>
        Cap! provides matching of investors and issuers of capital and
        shall provide secure settlement of shares in the future. We are
        more than a private placement platform, great businesses are
        enrolled on the portal through-the-growth-cycle rather than only
        when they need capital – so investors can track great businesses
        as they grow. We seek to match select credible investors to great
        businesses.
      </p>
      <Link href="/signup">
        <button>Investor sign up</button>
      </Link>
    </TitleSection>
  );
};

export default ForInvestors;

