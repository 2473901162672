import styles from './slide.module.scss';
import clsx from 'clsx';

const Slide = ({ image, text, author, role, location, locationFlag }) => {
  return (
    <div className={styles.slide}>
      <h2 className={clsx(styles.slide__title, 'hide-for-large')}>
        Testimonials
      </h2>
      <div className={styles.slide__image}>
        {image}
      </div>
      <div className={styles.slide__content}>
        <p className={clsx(styles.slide__title, 'show-for-large')}>
          Testimonials
        </p>
        <div className={styles.slide__text}>
          {text}
        </div>
        <p className={styles.slide__author}>{author}</p>
        <p className={styles.slide__role}>{role}</p>
        <p className={styles.slide__location}>
          {locationFlag}
          <span>{location}</span>
        </p>
      </div>
    </div>
  );
};

export default Slide;

