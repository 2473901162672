import clsx from 'clsx';
import styles from './titleSection.module.scss';

const TitleSection = ({
  heading,
  title,
  description,
  image,
  swapImage,
  children,
  containerClass,
  fullText,
}) => {
  return (
    <section className={clsx(styles.section, containerClass)}>
      <div
        className={clsx(
          styles.section__wrapper,
          swapImage && styles.swapImage,
          fullText && styles.fullText
        )}
      >
        <div className={styles.section__image}>{image}</div>
        <div className={styles.section__textWrapper}>
          <h2 className={styles.section__heading}>{heading}</h2>
          <h3 className={styles.section__title}>{title}</h3>
          {description && <p className={styles.section__desc}>{description}</p>}
          <div className={styles.section__content}>{children}</div>
        </div>
      </div>
    </section>
  );
};

export default TitleSection;

