import PropTypes from 'prop-types';
import styles from './marketingLayout.module.scss';
import Footer from '../footer/Footer';
import NewHeaderNav from '../headerNav/NewHeaderNav';

const MarketingLayout = ({ bgImage, headerCenterLinks, footerProps, children }) => {
  return (
    <>
      <main className={styles.container}>
        {bgImage}
        <div className={styles.header}>
          <NewHeaderNav
            centerLinks={headerCenterLinks}
            mobileLinks={headerCenterLinks}
          />
        </div>
        {children}
      </main>
      <Footer {...footerProps} />
    </>
  );
};

MarketingLayout.propTypes = {
  bgImage: PropTypes.node,
};

export default MarketingLayout;
