import styles from './landing.module.scss';
import MarketingLayout from '../layout/MarketingLayout';
import Hero from './Hero';
import BetaCompanies from './BetaCompanies';
import BannerSection from './BannerSection';
import ForCompanyManagement from './ForCompanyManagement';
import ForInvestors from './ForInvestors';
import WhyWeStarted from './WhyWeStarted';
import TrustedAdvisor from './TrustedAdvisor';
import Testimonials from './testimonials/Testimonials';
import SignupToday from './SignupToday';
import { useEffect, useRef, useState } from 'react';

const ScrollMarker = ({ markerRef, top, bottom }) => (
  <div
    ref={markerRef}
    className={styles.landing__marker}
    style={{ bottom, top }}
  />
);


const Landing = () => {
  const whyWeStartedRef = useRef();
  const whoWeAreForRef = useRef();
  const testimonialsRef = useRef();
  const [isWhyWeStartedActive, setWhyWeStartedActive] = useState(false)

  const scrollToWhy = () =>
    whyWeStartedRef.current.scrollIntoView({ behavior: "smooth" });

  const scrollToWho = () =>
    whoWeAreForRef.current.scrollIntoView({ behavior: "smooth" });

  const scrollToTestimonials = () =>
    testimonialsRef.current.scrollIntoView({ behavior: "smooth" });

  // active link activation
  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].boundingClientRect.y < 0) {
        setWhyWeStartedActive(true);
      } else {
        setWhyWeStartedActive(false);
      }
    });
    observer.observe(whyWeStartedRef.current);

    return () => observer.disconnect();
  }, [whyWeStartedRef]);

  const headerLinks = [
    {
      label: "Who we are for",
      onClick: scrollToWho,
      active: !isWhyWeStartedActive,
    },
    {
      label: "Why we started",
      onClick: scrollToWhy,
      active: isWhyWeStartedActive,
    },
  ];

  return (
    <MarketingLayout
      headerCenterLinks={headerLinks}
      footerProps={{
        onClickWhoAreWeFor: scrollToWho,
        onClickWhyWeStarted: scrollToWhy,
        onClickTestimonials: scrollToTestimonials,
      }}
    >
      <Hero>
        <ScrollMarker markerRef={whoWeAreForRef} bottom="200px" />
      </Hero>
      <ForCompanyManagement />
      <BetaCompanies />
      <BannerSection prefix='Companies' />
      <ForInvestors />
      <BannerSection prefix='Investors'>
        <ScrollMarker markerRef={whyWeStartedRef} top="200px" />
      </BannerSection>
      <WhyWeStarted />
      <TrustedAdvisor />
      <Testimonials>
        <ScrollMarker markerRef={testimonialsRef} top="-60px"/>
      </Testimonials>
      <SignupToday />
    </MarketingLayout>
  );
}

export default Landing;
