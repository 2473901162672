import styles from './trustedAdvisor.module.scss';
import TrustedCard from './TrustedCard';

const TrustedAdvisor = () => {
  return (
    <section className={styles.trusted}>
      <div className={styles.trusted__wrapper}>
        <img
          className={styles.trusted__cartaLogo}
          src="/marketing/carta-c.png"
          alt="Carta Advisory"
        />
        <h2 className={styles.trusted__title}>Our trusted advisor Carta</h2>
        <p className={styles.trusted__desc}>
          Through our affiliation with{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://carta-advisory.com/"
          >
            Carta Advisory
          </a>
          , investors and company management are able to gain insights into the
          capital raising process. Carta was established in 2019 to provide
          independent advice to great businesses and investors.
        </p>

        <h3 className={styles.trusted__subtitle}>
          Carta Advisory offers a range of corporate finance services,
          including:
        </h3>

        <div className={styles.trusted__cards}>
          <TrustedCard
            image="/marketing/right-partner.png"
            imageStyle={{ width: "141px", height: "141px" }}
            title="Finding the right capital partner for your business"
          />
          <TrustedCard
            image="/marketing/advisory-support.png"
            imageStyle={{ width: "142px", height: "179px", top: "-32px" }}
            title="Buy-side and sell-side advisory support"
          />
          <TrustedCard
            image="/marketing/valuation.png"
            imageStyle={{ width: "133px", height: "112px", top: "" }}
            title="Valuation support"
          />
          <TrustedCard
            image="/marketing/transaction-execution.png"
            imageStyle={{ width: "121px", height: "98px", top: "" }}
            title="Transaction execution support"
          />
          <TrustedCard
            image="/marketing/transaction-execution.png"
            imageStyle={{ width: "129px", height: "110px", top: "" }}
            title="Implement efficient funding structures"
          />
        </div>
      </div>
    </section>
  );
};

export default TrustedAdvisor;

