import styles from './whyWeStarted.module.scss';
import TitleSection from "./TitleSection";

const WhyWeStarted = () => {
  return (
    <TitleSection
      containerClass={styles.why}
      heading="Why we started"
      title="Cap! exists to help great companies focus on their own business by making managing, and raising, capital simple."
      fullText
    >
      <p>
        Capital markets are changing and we will be part of the change. Private
        capital markets provide access to large pools of capital yet shortage of
        information, and poor connectivity, has led to imperfect distribution of
        capital.&nbsp;
        <strong>
          We believe great businesses should not struggle to find the right
          investors – wherever in the world they may be.
        </strong>
      </p>
    </TitleSection>
  );
};

export default WhyWeStarted;
