import styles from './signupToday.module.scss';
import Link from 'next/link';

const SignupToday = () => {
  return (
    <section className={styles.signup}>
      <div className={styles.signup__wrapper}>
        <h2 className={styles.signup__title}>
          Connecting capital providers with great businesses
        </h2>
        <p className={styles.signup__desc}>
          Providing management with tools for secure company administration and
          fundraising
        </p>
        <Link href="/signup">
          <button className={styles.signup__btn}>Sign up today!</button>
        </Link>
      </div>
    </section>
  );
};

export default SignupToday;

