import styles from './testimonials.module.scss';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import clsx from 'clsx';
import { useState } from 'react';
import { slides } from './slides';

const Testimonials = ({ children }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  return (
    <section className={styles.test}>
      {children}
      <div className={styles.test__wrapper}>
        <Carousel
          emulateTouch
          showThumbs={false}
          showIndicators={false}
          showStatus={false}
          showArrows={false}
          autoPlay={true}
          interval={5000}
          useKeyboardArrows
          infiniteLoop
          selectedItem={currentSlide}
          onChange={(index) => {
            if (currentSlide !== index) {
              setCurrentSlide(index);
            }
          }}
        >
          {slides}
        </Carousel>

        <ul className={styles.test__indicators}>
          {slides.map((_, i) => (
            <li key={i}>
              <button
                className={clsx(
                  styles.test__indicator,
                  currentSlide === i && styles["test__indicator--active"]
                )}
                onClick={() => setCurrentSlide(i)}
              />
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default Testimonials;

