import styles from './footer.module.scss';
import clsx from 'clsx';
import Twitter from '../icons/Twitter';
import LinkedIn from '../icons/LinkedIn';

const Footer = ({ onClickWhoAreWeFor, onClickWhyWeStarted, onClickTestimonials }) => {
  return (
    <footer className={styles.footer} id='footer'>
      <div className={styles.footer__wrapper}>
        <div className={styles.footer__sections}>
          <div className={styles.footer__logo}>
            <img src="/cap-logo-white.svg" alt="Cap! Logo" />
          </div>

          <div className={styles.footer__linksWrapper}>
            <ul className={clsx([styles.links, styles['links--about']])}>
              <li className={styles.links__header}>About cap!</li>
              <li className={styles.links__link}>
                <button onClick={onClickWhoAreWeFor}>
                  Who we are for
                </button>
              </li>
              <li className={styles.links__link}>
                <button onClick={onClickWhyWeStarted}>
                  Why we started
                </button>
              </li>
              <li className={styles.links__link}>
                <button onClick={onClickTestimonials}>
                  Testimonials
                </button>
              </li>
            </ul>
            <ul className={styles.links}>
              <li className={styles.links__header}>Contact Us</li>
              <li className={styles.links__link}>
                <a href="mailto:info@cap-equity.com">info@cap-equity.com</a>
              </li>
              <li className={styles.links__link}>NSW, Australia</li>
            </ul>
            <ul className={clsx([styles.links, styles['links--carta']])}>
              <li className={styles.links__header}>Carta Advisory</li>
              <li className={styles.links__link}>
                <a href="mailto:sam.pearson@carta-advisory.com">
                  sam.pearson@carta-advisory.com
                </a>
              </li>
              <li className={styles.links__link}>+61 (0)410 806 029</li>
              <li className={styles.links__link}>NSW, Australia</li>
            </ul>
          </div>
          <ul className={clsx([styles.links, styles["links--social"]])}>
            <li className={styles.links__header}>Social</li>
            <li className={styles.links__link}>
              <a
                href='https://www.linkedin.com/company/cap-equity-management'
                target='_blank'
                rel="noreferrer"
              >
                <LinkedIn currentColor />
              </a>
            </li>
          </ul>
        </div>

        <div className={styles.legal}>
          <ul className={styles.legal__links}>
            <li className={styles.legal__link}>
              <a
                href="/data-processing-agreement"
                target="_blank"
                rel="noreferrer"
              >
                Data Processing Agreement
              </a>
            </li>
            <li className={styles.legal__link}>
              <a href="/user-terms-of-service" target="_blank" rel="noreferrer">
                User Terms of Service
              </a>
            </li>
            <li className={styles.legal__link}>
              <a
                href="/customer-terms-of-service"
                target="_blank"
                rel="noreferrer"
              >
                Customer Terms of Service
              </a>
            </li>
            <li className={clsx([styles.legal__link, styles.lastDoc])}>
              <a href="/privacy-policy" target="_blank" rel="noreferrer">
                Cookie Policy
              </a>
            </li>
            <li className={styles.legal__link}>
              <p>
                &copy; {new Date().getFullYear()} XXX All rights reserved.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
