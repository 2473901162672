import styles from './trustedCard.module.scss';

const TrustedCard = ({ image, title, imageStyle }) => {
  return (
    <div className={styles.card}>
      <div className={styles.card__imageWrapper}>
        <img
          style={imageStyle}
          className={styles.card__image}
          src={image}
          alt={title}
        />
      </div>
      <p className={styles.card__title}>{title}</p>
    </div>
  );
};

export default TrustedCard;

