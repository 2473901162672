import TitleSection from "./TitleSection";
import Link from "next/link";

const ForCompanyManagement = () => {
  return (
    <TitleSection
      heading="Who we are for"
      title="For Company Management"
      description="Join for US$99 per month"
      image={
        <img
          src="/marketing/company-management.png"
          alt="Company management"
          style={{ width: "430px" }}
        />
      }
    >
      <p>
        <strong>
          The Cap! platform securely presents your company information
          relevant to the investment decision process in an organised and
          professional manner.
        </strong>
      </p>
      <p>
        It is a day-to-day tool for managing your existing investors and
        is a powerful database to access and interact with new investors
        for your upcoming capital raise round.
      </p>
      <Link href="/signup">
        <button>Company sign up</button>
      </Link>
    </TitleSection>
  );
};

export default ForCompanyManagement;

