import styles from './betaCompanies.module.scss';

const BetaCompanies = () => {
  return (
    <section className={styles.beta}>
      <h2 className={styles.beta__title}>Beta companies on Cap!</h2>
      <ul className={styles.beta__list}>
        <li>
          <a href="/standard-microgrid" className={styles.beta__link}>
            <img
              className={styles["beta__logo--sm"]}
              src="/standardmicrogrid.png"
              alt="Standard Microgrid"
            />
          </a>
        </li>
        <li>
          <a href="/the-duchess" className={styles.beta__link}>
            <img
              className={styles["beta__logo--duchess"]}
              src="/duchess.png"
              alt="The Duchess"
            />
          </a>
        </li>
        <li>
          <a href="/devils-peak" className={styles.beta__link}>
            <img
              className={styles["beta__logo--devilspeak"]}
              src="/devilspeak.png"
              alt="Devil's Peak"
            />
          </a>
        </li>
        <li>
          <a href="/gosolr" className={styles.beta__link}>
            <img
              className={styles["beta__logo--gosolr"]}
              src="/gosolr.png"
              alt="GoSolr"
            />
          </a>
        </li>
        <li>
          <a href="/plentify">
            <img
              className={styles["beta__logo--plentify"]}
              src="/plentify.png"
              alt="Plentify"
            />
          </a>
        </li>
      </ul>
    </section>
  );
};

export default BetaCompanies;

